<template>
  <div>
    <CarouselMain v-if="showCarousel" :page="page" />
    <Breadcrumbs v-if="!isMainPage" :breadcrumbs="breadcrumbs" />
    <Content :page="page" />
    <News v-if="isMainPage"/>
    <ContactForm v-if="showForm" :form="page.form"/>
  </div>
</template>

<script>
import {rememberExpiresMixin, headPageMixin} from '@/mixins';
import {DbConfig} from '@/models/db-config';

import Breadcrumbs from '@/components/breadcrumbs';
import CarouselMain from '@/components/carousel-main';
import ContactForm from '@/components/contact-form';
import Content from '@/components/content';
import News from '@/components/news';

export default {
  components: {
    Breadcrumbs,
    CarouselMain,
    ContactForm,
    Content,
    News,
  },
  mixins: [
    rememberExpiresMixin,
    headPageMixin
  ],
  computed: {
    showCarousel() {
      return this.page && (
        (this.page.banner_items && this.page.banner_items.filter(x => x.visible).length > 0) ||
        (this.page.banner_items_mobile && this.page.banner_items_mobile.filter(x => x.visible).length > 0)
      );
    },
    isMainPage() {
      return this.$route.path === this.localePath('/');
    },
    showForm() {
      return this.page.form && this.page.form.form_fields && this.page.form.form_fields.length;
    },
    breadcrumbs() {
      let title = this.page.page_title_translations?.find(x => x.language.slug === this.$i18n.locale)?.title || this.page.title;
      return [{name: title}];
    }
  },
  async asyncData(context) {
    try {
      const path = context.route.path.replace(context.i18n.locale + '/', '');
      const cl = true;
      const [dbConfig, page] = await Promise.all([
        DbConfig.config({count_loading: cl}).first(),
        context.$axios.$get('/pages/find/', {params: {html_path: path}, count_loading: cl}),
      ]);
      return {
        dbConfig: dbConfig,
        page: page,
      }
    } catch (e) {
      console.error(e)
      context.error({statusCode: e.response?.status ? e.response?.status : 500});
    }
  },
}
</script>
